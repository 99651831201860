#google-translate-element {
    display:none;
}

.skiptranslate {
    display: none;
}

.translate {
    .google_translate_element {
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;

        select {
            height: 3rem;
            margin: 0;
            outline: none !important;
            width: 100%;
        }

        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }
}

.language-toggle {
    width:2rem;
    &::after {
        display: none;
    }
    img {
        width:100%;
    }
}

.stop-translation {
    width: .5rem;
    height: .5rem;
    right: -.6rem;
}

.translation-options {
    max-height: 13rem;
    overflow-y: scroll;
}

.language-dropdown {
    position: relative;
    &.offset-top {
        padding-top: .5rem;
    }
}

// Override style by Google
html.translated-ltr {
    .header__top {
        top: 3rem;
    }
}

body:not(.locked) {
    top: auto !important;
}

body > .skiptranslate:first-child {
    overflow-x: none;
}

.goog-te-banner-frame {
    min-width: 50rem;
    position: relative !important;
    z-index: 1000 !important;
}
