.productcard {
	.badges {
		top: 7px;
		right: -7px;
	}

	img {
		transition: none;
	}

	&:hover {
		img {
			transform: scale(1);
		}
		picture.fade {
			opacity:1;
		}
	}
}
