@import "./_utils/px2rem";

// Colors
$grays: (
	100: "",
	200: "",
	300: "",
	400: "",
	500: "",
	600: "",
	700: "",
	800: "",
	900: "",
);

$black: #000000;
$white: #ffffff;

$primary: #000;
$secondary: #ec008c;
$tertiary: #f3ecea;

$muted: #626262;
$muted-light: #BCBCBC;

$info: yellow;
$success: #069E2D;
$orange: #f17c16;
$warning: yellow;
$error: red;

$border-color: $tertiary;

$carousel-transition-duration: .4s;

$spacer: 1.2rem;

$theme-colors: (
	"primary": $primary,
	"secondary": $secondary,
	"tertiary": $tertiary,
	"success": $success,
	"info": $info,
	"warning": $warning,
	"error": $error,
	"green" : $success,
	"orange" : $orange,
	"white" : $white,
	"muted": $muted,
	"muted-light": $muted-light,
);

// Fonts
$font-family-title: "Poppins";
$font-family-title-weights: "ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400";

$font-family: "Poppins";
$font-family-weights: "ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400";

// Font sizes
$fs: (
	100: rem(12),
	200: rem(14),
	300: rem(16),
	400: rem(18),
	500: rem(24),
	600: rem(28),
	700: rem(32),
	800: rem(36),
	850: rem(46),
	900: rem(56),
);

// Font weights
$fw: (
	100: 100,
	200: 200,
	300: 300,
	400: 400,
	500: 500,
	600: 600,
	700: 700,
	800: 800,
	900: 900,
);

$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 2,
  6: $spacer * 2.5,
  7: $spacer * 3,
);

// Custom grid
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1390px,
);

$container-max-widths: (
	xxl: 1390px,
);

//Aspect ratios
$aspect-ratios: (
	"1x1": 100%,
	"4x3": calc(3 / 4 * 100%),
	"16x9": calc(9 / 16 * 100%),
	"21x9": calc(9 / 21 * 100%),
	"34x7": calc(7 / 34 * 100%),
	"16x13": calc(16 / 13 * 100%),
);
