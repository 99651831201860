$childrenCount: 1, 2, 3, 4, 5;

.simpleslider {
    @each $count in $childrenCount {
        &.children-#{$count} {
            @if $count > 1 {
                & > * {
                    $incrementer: 100% / 3;
                    animation-duration: #{5 * $count}s;
                    animation-name:animation-#{$count};
                    animation-iteration-count: infinite;
                    animation-timing-function: cubic-bezier(.9, 0, 0, .9);
                    @keyframes animation-#{$count} {
                        0% {transform: translate(0%)}
                        99% {
                            transform: translate($count * -100%);
                            animation-timing-function: steps(1, end);
                        }
                        100% {transform: translate($count * 0%)}
                        @for $i from 1 through $count - 1 {
                            $percent: (100% / ($count)) * $i;
                            #{$percent} {transform: translate($i * -100%)}
                        }
                    }
                }
            }
        }
    }
}