.newsletter {
    position: relative;

    &__message {
        position: absolute;
        bottom: -1.5rem;
        font-size: map-get($fs, 200);

        &--success {
            color: $success;
        }

        &--danger {
            color: $error;
        }
    }
}

.newsletter-modal {
    &--visual {
        min-height: 20rem;
        @include media-breakpoint-down(md) {
            min-height: 14rem;
        }
    }
}
