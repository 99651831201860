.row {
    &.two-third-columns {
        @include media-breakpoint-up(md) {
            > * {width: calc((100% / 3) * 2);}
        }

        @include media-breakpoint-down(md) {
            > * {width: 100%;}
        }
    }

    &.full-width-columns {
        > * {width: 100%;}
    }
}