$widths: 7, 10, 15, 20, 30, 50, 65, 70, 150, 200, 250;

@each $width in $widths {
    .w-#{$width}px {
        flex: 0 0 #{$width}px;
        width: #{$width}px;
    }

    .h-#{$width}px {
        height: #{$width}px;
    }
}

.fit-content {
    width:fit-content;
}