.microcart:not(.show){
    display: none;
}
.js-search-bar:not(.show){
    display: none;
}
.readmore__content{
    height: 105px;
}
.header{
    @include media-breakpoint-up(md) {
        height: 112px;
    }
}
