.footer {
    &__payment-logo{
        border: 1px solid #f2f2f2;
        border-radius: 4px;
        padding: 2px;
        height: 40px;
        width: 65px;

        svg{
            max-width: 100%;
            max-height:100%;
        }
    }
}
