.collection {
	button {
		width: 40px;
		padding-left: 10px;
		padding-right: 10px;

		@include media-breakpoint-down(md) {
			display: none;
		}
	}

	.slider {
		&-inner {
			transition: 0.1s ease-in-out transform;
			text-align: left;
			display: inline-block;
		}

		&-container {
			width: calc(100% - 20px);
			white-space: nowrap;
			overflow: hidden;

			@include media-breakpoint-up(md) {
				max-width: 1105px;
			}

			a {
				width: 165px;
				height: 210px;
				
				margin-left: 10px;
				margin-right: 10px;			

				display: inline-block;

				svg {
					position: absolute;
					z-index: 1;
					width: 14px;
					left: calc(100% - 27px);
					top: calc(100% - 35px);
					height: 20px;
				}
			}
		}
	}
}
