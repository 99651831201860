input,
textarea {
	background: transparent;
	border: none;

	&:focus-visible {
		outline: none;
	}

	font-family: poppins;
	font-size: map-get($fs, 200) !important;

	&::placeholder {
		font-style: italic;
	}
}

input {
	border: 1px $tertiary solid;
	flex-grow: 0;
	flex-shrink: 0;
}

input[type="number"],
input[type="text"],
input[type="email"],
input[type="invoice"],
input[type="shipping"],
input[type="date"] {
	min-height: 40px;
	padding: 13px;
}

select {
	padding: 13px 3px;
	border: 2px solid $tertiary;
}

textarea {
	min-height: 200px;
}

button {
	cursor: pointer;
}

input[type="checkbox"] {
	appearance: none;
	margin: 0;
	cursor: pointer;
	font: inherit;
	color: currentColor;
	width: 24px;
	height: 24px;
	border: 0.15em solid $muted-light;
	border-radius: 0.4em;
	display: grid;
	place-content: center;

	&:before {
		content: "";
		width: 14px;
		height: 14px;
		transform: scale(0);
		transition: 60ms transform ease-in-out;
		box-shadow: inset 1em 1em $primary;
		border-radius: 4px;
	}

	&:checked {
		&::before {
			transform: scale(1);
		}
		border-color: $primary;
	}

	&.rounded-circle {
		&:before {
			border-radius: 50%;
		}
	}
}

input[type="number"] {
	appearance: textfield;
	width: 4rem;
	text-align: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

input[type="radio"] {
	appearance: none;
	margin: 0;
	cursor: pointer;
	font: inherit;
	color: currentColor;
	width: 24px;
	height: 24px;
	border: 0.15em solid $muted-light;
	border-radius: 50%;
	transform: translateY(-0.075em);
	display: grid;
	place-content: center;

	&:before {
		content: "";
		width: 14px;
		height: 14px;
		transform: scale(0);
		transition: 60ms transform ease-in-out;
		box-shadow: inset 1em 1em $primary;
		border-radius: 50%;
	}

	&:checked {
		&::before {
			transform: scale(1);
		}
		border-color: $primary;
	}
}

select {
	border-radius: 0;
	padding: $spacer #{$spacer * 2} $spacer $spacer;
	background-color: white;
	color: black;
	font-size: map-get($fs, 300);
	appearance: none;
	background: url("data:image/svg+xml,%3Csvg width='12' height='8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-.00006014 1.85321 5.70694 7.56021l5.70696-5.707L9.99994.439209l-4.293 4.293001-4.293-4.293001L-.00006014 1.85321Z' fill='%23000'/%3E%3C/svg%3E")
		no-repeat scroll calc(100% - 1rem - 3px) center/12px auto;
}

.numberinput-controls {
	display: flex;
	input {
		padding-top: 0;
		padding-bottom: 0;
	}

	path {
		fill: black;
		stroke: black;
	}

	> button:first-child {
		width: 40px;
		background: $tertiary;
		padding: 5px 15px;
		border-radius: 0.25rem 0 0 0.25rem;
	}

	> button:last-child {
		width: 40px;
		background: $tertiary;
		padding: 5px 15px;
		border-radius: 0 0.25rem 0.25rem 0;
	}
}

.input {
	&[data-touched] {
        &[data-error]:not([data-valid]):not(:focus-within) {
            .input__group {
                background: rgba($error, .05);
                border: 1px solid $error;
                margin-bottom: rem(8);
            }

            &::after {
                content: attr(data-error);
				font-size: map-get($fs, 200);
				color: $error;
            }
        }
    }

    &:invalid{
        border: 1px solid $error;
    }

    [data-valid] input {
		border: $success 1px solid;
		background: rgba($success, .05) !important;
	}
}
form{
    input {
        &:focus {
            &:invalid {
                border: 1px solid $error;
            }
        }
    }
}
