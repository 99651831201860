.horizontal {
    overflow: hidden;

    $title-color: $primary;

    @include media-breakpoint-up(lg) {
        overflow: initial;
    }

    &--homepage {
        @include media-breakpoint-down(md) {
            background: $gray-100;
        }
    }

    &__container {
        overflow: initial;

        @include media-breakpoint-down(md) {
            &[data-mobile]:not(.slider) {
                opacity: 0;
            }
        }

        @include media-breakpoint-up(lg) {
            &:not(.slider) {
                opacity: 0;
            }
        }
    }

    &__track, &__actions {
        margin-right: rem(-5);
        margin-left: rem(-5);

        div[class*="col"] {
            padding-right: rem(5);
            padding-left: rem(5);
        }

        .horizontal__container.slider & {
            margin-right: rem(-15);
            margin-left: rem(-15);

            div[class*="col"] {
                &:first-child {
                    padding-left: rem(15);
                }

                &:last-child {
                    padding-right: rem(15);
                }
            }

            @include media-breakpoint-up(lg) {
                margin-right: rem(-10);
                margin-left: rem(-10);

                div[class*="col"] {
                    padding-right: rem(10);
                    padding-left: rem(10);
                }
            }
        }
    }

    &__action {
        padding: .75rem;
        background: $white;
        border: 1px solid $gray-400;
        border-radius: rem(3);

        svg {
            width: rem(25);
            height: rem(22);

            path {
                fill: $title-color;
            }
        }
    }

    &__arrow {
        left: -10px;

        svg path {
            @include transition;
        }

        &[disabled] {
            pointer-events: initial;
            cursor: not-allowed;
            opacity: 1;
            visibility: visible;

            svg path {
                fill: $gray-400;
            }
        }

        &--prev-0 {
            left: 0;
        }

        &--next {
            right: -10px;
            left: initial;
        }

        @media (min-width: 1440px) and (max-width: 1460px) {
            left: 0;

            &--next {
                right: 0;
                left: initial;
            }
        }
    }
}
