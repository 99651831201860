*[class*="fs-"] {
    line-height: 1.25;
}

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        @each $size in map-keys($fs) {
            @if $breakpoint == 'xs' {
                .fs-#{$size} {
                    font-size: map-get($fs, $size) !important;
                }
            } @else {
                .fs-#{$breakpoint}-#{$size} {
                    font-size: map-get($fs, $size) !important;
                }
            }
        }

        @each $weight in map-keys($fw) {
            @if $breakpoint == 'xs' {
                .fw-#{map-get($fw, $weight)} {
                    font-weight: map-get($fw, $weight) !important;
                }
            } @else {
                .fw-#{$breakpoint}-#{map-get($fw, $weight)} {
                    font-weight: map-get($fw, $weight) !important;
                }
            }
        }
    }
}
