.saleblock {
	width: 255px;
	height: 100px;

	.image-container {
		width: 95px;
	}
	svg {
		width: 7px;
	}
}
