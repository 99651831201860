.breadcrumbs {
    @extend .fs-100;

    a {
        color:$text-muted;
        text-decoration: none;
    }

    a:last-child {
        opacity: 0.5;
    }
}