.btn {
	border-radius: 0;
	color: black;
	text-transform: uppercase;
	font-weight: 600;
	font-size: 1rem;
	display: inline-flex;
	justify-content: space-between;
	align-items: center;
	font-family: $font-family-title;
	text-align: left;
	gap: 0.75rem;
	padding: 0.7rem 0.9rem;
	transition: 0.2s ease-in-out background-color;
	border: none;
	text-decoration: none;

	&:disabled,
	&.disabled {
		opacity: 0.25;
	}

	svg {
		width: 8px;
	}

	&.icon-default svg {
		width: 8px;
	}

	&.icon-large svg {
		width: 15px;
		flex: 0 0 15px;
	}

	&.collapsable {
		box-shadow: none !important;

		.icon-minus {
			display: block;
		}
		.icon-plus {
			display: none;
		}

		&.collapsed {
			.icon-plus {
				display: block;
			}
			.icon-minus {
				display: none;
			}
		}
	}

	&.btn-secondary,
	&.btn-primary {
		color: white;
		path {
			fill: white;
		}
	}

	&.btn-primary:hover {
		background-color: darken($primary, 5%);
	}

	&.btn-secondary:hover {
		background-color: darken($secondary, 5%);
	}

	&.btn-success {
		color: white;
		path {
			fill: white;
		}
		&:hover {
			background-color: darken($success, 1.1);
		}
	}

	&.hover-secondary {
		transition: 0.2s ease-in-out all;

		path {
			transition: 0.2s ease-in-out all;
		}

		&:hover {
			background-color: $secondary;
			color: white;

			path {
				fill: white;
			}
		}
	}
}

.btn.round {
	svg {
		transition: 0.15s ease-in-out transform;
	}

	&:hover svg {
		transform: scale(0.8);
	}
}

.round {
	&-xs {
		height: 15px;
		padding: 3.5px;
		max-width: 15px;
	}
	&-s {
		height: 20px;
		padding: 4.5px;
		max-width: 20px;
	}
	width: 100%;
	max-width: 35px;
	height: 35px;
	border-radius: 50%;
	padding: 8px;
	display: flex !important;
	justify-content: center;
	align-items: center;

	svg {
		width: 100%;
	}
}

.nav-btn {
	color: white;
	text-decoration: none;
	align-self: center;

	&:hover {
		cursor: pointer;
		text-decoration: none;
	}

	&.tertiary.active {
		color: $primary;
		background-color: $primary;
		path {
			fill: white;
		}
	}

	&:hover,
	&.active {
		color: $primary;
		background-color: white;
		path {
			fill: black;
		}
	}
}
