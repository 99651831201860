.popover {
    top: 0px;
	right: -1px;

	@include media-breakpoint-down(md) {
		width: calc(100vw - 1rem);
	}
	
	.arrow {
		right: 30px;
		width: 10px;
		height: 10px;
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
		border-bottom: 10px solid $tertiary;
        position: absolute;
        top: -10px;
	}
}
