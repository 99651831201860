.icon {
    &-black path {
        fill:black;
    }

    &-white path {
        fill:white;
    }

    &-secondary path {
        fill:$secondary;
    }

    &-black-path path {
        stroke: black;
    }

    &-white-path path {
        stroke: white;
    }

    &-secondary-path path {
        stroke: $secondary;
    }

    &-sm {
        width:8px;
        svg {
            width:8px;
        }
    }

    &-md {
        width:10px;
        svg {
            width:10px;
        }
    }
    &-lg {
        width:26px;

        svg {
            width: 26px;
        }
    }
    &-xl {
        width:32px;

        svg {
            width: 32px;
        }
    }
    &-xxl {
        width:46px;

        svg {
            width: 46px;
        }
    }
}