.sooqrSearchContainer{
    position: fixed !important;
    bottom: 0 !important;
    height: initial !important;
    min-height: 0 !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    top: 189.084px !important;
    @include media-breakpoint-down(lg) {
        top: 160px !important;
    }
    max-width: 100%;
    
    .sooqrSearchFilters {
        display: none;
    }

    .sooqrSearchResultsContainer {
        position: absolute !important;
        top: rem(51) !important;
        right: 0 !important;
        bottom: 0 !important;
        left: 0 !important;
        margin: 0 !important;
        
        overflow-y: scroll !important;
        -webkit-overflow-scrolling: touch !important;

        @include media-breakpoint-up(md) {
            top: 0 !important;
        }
    }

    .sqr-options {
        position: sticky !important;
        z-index: 11;
        top: 0 !important;
    }
}

html body div.sooqrSearchContainer div.sooqrSearchResultsContainer div.sooqrSearchResults div.sqr-results.sqr-detail div.sqr-right div.sqr-order .sqr-button {
    display: none;
}
