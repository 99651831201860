table:not(.clean) {
	border-spacing: 0;
	tr {
		background-color: white;
		&:nth-of-type(even) {
			background-color: #ede1de;
		}
	}

	th,
	td {
		border: 0;		
	}

	th {
		text-transform:uppercase;
		background-color: $primary;
		color: white;
	}
}

th,td {
	@extend .p-3;
	text-align: left;
}