.slider {
    position: relative;

    &__track {
        user-select: none;
        position: relative;
        z-index: 1;
        display: flex;
        padding: .5rem 0;
        flex-wrap: nowrap;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: none;
        scrollbar-width: none !important;

        &::-webkit-scrollbar {
            display: none;
            -webkit-appearance: none;
        }
    }

    &__arrow {
        @include media-breakpoint-down(md) 
        {
            transition: all .2s ease-in-out !important;
        }
        
        cursor: pointer;
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 0;
        transform: translateY(-50%);

        @include media-breakpoint-down(md) {
            display: none;
        }

        &[disabled] {
            pointer-events: none;
            opacity: 0;
            visibility: hidden;
        }

        &--next {
            right: 0;
            left: initial;
        }
    }

    &__slide {
        .slider[data-dragging] & {
            pointer-events: none;
        }
    }
}
