.readmore {
    position: relative;

    &--hidden {
        max-height: 80px;
        overflow: hidden;

        &:after {
            position: absolute;
            right: 0;
            left: 0;
            bottom: 0;
            content: '';
            height: 50px;
            width: 100%;
            background: linear-gradient(0deg, white, rgba(255, 255, 255, 0));
        }
    }

    &__collapse {
        z-index: 1;
    }
}
