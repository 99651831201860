.editor {
	display: flex;
	gap: #{$spacer / 2};
	flex-direction: column;

	h1 {
		text-transform: uppercase;
		font-weight: map-get($fw, 700);
		font-size: map-get($fs, 850);
		text-transform: uppercase;
		line-height: 3rem;

		@include media-breakpoint-down(md) {
			font-size: map-get($fs, 700);
			line-height: 2.3rem;
		}
	}

	h2 {
		font-weight: map-get($fw, 700);
		font-size: map-get($fs, 400);
		text-transform: uppercase;

		@extend .lh-base;
	}

	h3 {
		font-weight: map-get($fw, 700);
		font-size: map-get($fs, 400);
		text-transform: uppercase;

		@extend .lh-base;
	}

	strong {
		color: $primary;
	}

	p {
		line-height: 1.3rem;
		color: $text-muted;

		font-size: map-get($fs, 200);

		small {
			line-height: 1rem;
		}
	}

	img {
		max-width: 100%;
	}

	ul li {
		list-style: disc;
		list-style-position: outside;
		margin-left: 18px;
		line-height: 1.2rem;

		color: $text-muted;

		font-size: map-get($fs, 200);
	}

	li {
		margin-bottom:5px;
	}

	ol li {
		margin-left: 13px;
		padding-left: 4px;
		line-height: 1.2rem;
		list-style: auto;

		color: $text-muted;

		font-size: map-get($fs, 200);
	}
}
