hr {
    border-style: solid;
    opacity: 0.1;
    border-bottom: none;

    &.lighter {
        border-color: #F0F0F0;
        opacity: 1;
    }

    &.light {
        border-color: white;
        opacity:1;
    }
}
