.add-to-cart-small {
    background-color: $primary !important;

    .abletoaddtocart {
        display: block;
    }
    .disableaddtocart {
        display: none;
    }
    &.added {
        background-color: $success !important;

        .abletoaddtocart {
            display: none;
        }

        .disableaddtocart {
            display: block;
        }
    }
}
