.badge {
    pointer-events: none;
    display:none;
    width: 20px;
    height: 20px;
}

.badge[data-count] {
    &:after {
        content: attr(data-count);
    }

    display: flex;
    justify-content: center;
    align-items: center;
}

.badge[data-count="0"] {
    display:none !important;
}