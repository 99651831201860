a {
    color: #0645AD;
    text-decoration: underline;

    img {
        transition:.15s ease-in-out transform;
    }
    
    &:hover {
        text-decoration: underline;
        color: #0B0080;
        img {
            transform: scale(1.075) rotate(1deg);
        }
    }

    &.light {
        color:white;
        &:hover {
            color:inherit;
        }
    }

    &.no-image-effect {
        &:hover {
            img {
                transform: none;
            }
        }
    }
}

a.text-muted {
    &:hover {
        color:$primary !important;
    }
}


.fade-on-hover:hover {
    opacity:0.5;
}