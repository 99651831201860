.container-sm {
	width: calc(1250px - #{$spacer * 2});
	max-width: 100%;
	margin-right: auto;
	margin-left: auto;
	padding-left: $spacer;
	padding-right: $spacer;
}

.container {
	padding-left: $spacer;
	padding-right: $spacer;
}

.container-xs {
	width: 970px;
	max-width: 100%;
	margin-right: auto;
	margin-left: auto;
	@media (max-width: 970px) {
		padding-left: $spacer;
		padding-right: $spacer;
	}
}
