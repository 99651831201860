 .reviewblock {
     &__link {
         color: $black;
         text-decoration: none;

         &:hover {
             color: $black;
         }
     }
 }
