.pointer-hover {
	&:hover {
        cursor:pointer;
	}
}

.pointer-zoom-in {
	&:hover {
        cursor:url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' height='18' width='18' viewBox='0 0 18 18'%3E%3Cpath d='m12.5 11 5 5-1.5 1.5-5-5v-.79l-.27-.28C9.55402 12.4439 8.05269 13.0011 6.5 13c-1.72391 0-3.37721-.6848-4.59619-1.9038C.684819 9.87721 0 8.22391 0 6.5c0-1.72391.684819-3.37721 1.90381-4.59619C3.12279.684819 4.77609 0 6.5 0s3.37721.684819 4.5962 1.90381C12.3152 3.12279 13 4.77609 13 6.5c0 1.61-.59 3.09-1.57 4.23l.28.27h.79Zm-6 0C9 11 11 9 11 6.5S9 2 6.5 2 2 4 2 6.5 4 11 6.5 11ZM9 7H7v2H6V7H4V6h2V4h1v2h2v1Z' fill='%23000'/%3E%3C/svg%3E") 9 9, pointer;
	}
}
