@import 'reboot';
@import 'base';
@import 'codesplitting-fixes';
@import 'fonts';

// Dependencies

@import '~bootstrap/scss/bootstrap-grid';
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/utilities/api";
@import "~bootstrap/scss/helpers";
@import "~bootstrap/scss/offcanvas";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/carousel";
@import '~lightgallery/css/lightgallery.css';
@import '~lightgallery/css/lg-thumbnail.css';
@import '~lightgallery/css/lg-zoom.css';
@import './slider';

// Atoms
@import './atoms/btn';
@import './atoms/icontoggle';
@import './atoms/content';
@import './atoms/font';
@import './atoms/addtocartsmall';
@import './atoms/heading';
@import './atoms/logo';
@import './atoms/column';
@import './atoms/img';
@import './atoms/opacity';
@import './atoms/input';
@import './atoms/gradient';
@import './atoms/pointer';
@import './atoms/tabs';
@import './atoms/modal';
@import './atoms/link';
@import './atoms/typography';
@import './atoms/row';
@import './atoms/border';
@import './atoms/price';
@import './atoms/horizontalrule';
@import './atoms/badge';
@import './atoms/transitions';
@import './atoms/icon';
@import './atoms/width';


// Components
@import './components/reviewblock';
@import './components/carousel';
@import './components/video';
@import './components/editor';
@import './components/horizontal';
@import './components/breadcrumb';
@import './components/readmore';
@import './components/popover';
@import './components/jumbotron';
@import './components/scrollbar';
@import './components/featuredblock';
@import './components/simpleslider';
@import './components/microcart';
@import './components/slider';
@import './components/productslider';
@import './components/backdrop';
@import './components/gallery';
@import './components/translate';
@import './components/saleblock';
@import './components/subcategoryslider';
@import './components/container';
@import './components/modal';
@import './components/productcard';
@import './components/row';
@import './components/offcanvas';
@import './components/collection';
@import './components/table';
@import './components/dragslider';
@import './components/dropdownmenu';
@import './components/newsletter';


// Organisms
@import './organisms/footer';
@import './organisms/header';
@import './organisms/sooqr';

body {
    -webkit-font-smoothing: subpixel-antialiased;
    background: $white;
    color: map-get($grays, 800);
    font-family: $font-family-title, Helvetica, sans-serif;

    // Add Bootstrap breakpoint to body
    &::after {
        content: 'xs';
        display: none;
    }

    @each $size in map-keys($container-max-widths) {
        @include media-breakpoint-up($size) {
            &::after {
                content: '#{$size}';
            }
        }
    }
    .grecaptcha-badge{
        display: none!important;
    }
}
html{
    scroll-padding-top: 150px;
}
