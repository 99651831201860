.video {
    cursor: pointer;

    path, svg, img {
        transition: .3s all ease-in-out;
    }

    path {
        fill: $white;
    }

    &:hover {
        path {
            fill: $secondary;
        }
    }
}