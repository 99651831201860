.scrollbar {
    position: relative;
    background-color: #F6F5F4;
    height:5px;
    .inner {
        position: absolute;
        top:0;
        bottom:0;
        min-width: 10px;
        background-color: black;
    }
}