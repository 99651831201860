.carousel{
    min-height: 346px;
    @include media-breakpoint-up(sm) {
        min-height: 507px;
    }
}
.carousel-item {
    min-height: 346px;
    @include media-breakpoint-up(sm) {
        min-height: 507px;
    }
}

.carousel-indicators [data-bs-target] {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

