.slider {
	&.small {
		.slider-inner > * {
			min-width: 65%;

			@include media-breakpoint-up(md) {
				min-width: calc(25% - #{$spacer} / 2);
			}

			@include media-breakpoint-up(xs) {
				min-width: calc(29% - #{$spacer} / 2);
			}

			@include media-breakpoint-up(xl) {
				min-width: calc(100% / 6 - #{$spacer} * 0.825);
			}
		}
	}

	.slider-inner > * {
		min-width: 65%;

		@include media-breakpoint-up(md) {
			min-width: calc(50% - #{$spacer} / 2);
		}

		@include media-breakpoint-up(xl) {
			min-width: calc(100% / 3 - #{$spacer} * 0.6725);
		}
	}

	.image-container {
		width: 70px;
	}

	.slider-inner {
		//transition: all .2s ease;
	}
}
