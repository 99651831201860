.microcart {
    .content {
        max-height: calc(100vh - 89px);
    }

	@include media-breakpoint-up(md) {
        min-width: 460px;
        max-width:500px;
	}

	.productimage {
		width: 65px;
	}
}
